const $ = require("jquery");
global.$ = global.jQuery = $;

require("slick-carousel");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");

require("bootstrap");

require("filepond/dist/filepond.min.css");
require("filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css");
const FilePondPluginImagePreview = require("filepond-plugin-image-preview/dist/filepond-plugin-image-preview");
const FilePondPluginFileValidateType = require("filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type");
const FilePondPluginFileValidateSize = require("filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size");
const FilePond = require("filepond");
FilePond.registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize
);
global.FilePond = FilePond;
const FilePondLocale_en = require("filepond/locale/en-en.js");
global.FilePondLocale_en = FilePondLocale_en.default;
const FilePondLocale_ja = require("filepond/locale/ja-ja.js");
global.FilePondLocale_ja = FilePondLocale_ja.default;
